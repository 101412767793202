import { every, pick } from 'lodash-es';
import { START_LOCATION } from 'vue-router';
import integrator from '@/services/integrator';
import { filtersParser } from '@/utility';
import constants from '@/utility/constants';
import store from '../store';
import config from '../../config';

const { replaceSpacesWithDashes, getIdFromParam, getNameFromParam } = filtersParser;

const getGroupParams = ({ groupId, groupName, subgroupId, subgroupName }) => {
  let param1;
  let param2;

  // default group 'all' so no need to send params
  if (groupId !== constants.GROUP_IDS.ALL) {
    param1 = `${groupId}-${replaceSpacesWithDashes(groupName)}`;
  }

  if (subgroupName) param2 = `${subgroupId}-${replaceSpacesWithDashes(subgroupName)}`;

  return {
    param1,
    param2,
  };
};

const getGameParams = (gameId, gameName) => {
  // Set explicit 'game' path for 3rd party integrations
  const param1 = constants.INTEGRATOR_PARAMS.GAME;
  const param2 = `${gameId}-${replaceSpacesWithDashes(gameName)}`;

  return {
    param1,
    param2,
  };
};

export default {
  handleInitialRoute: async (to, from) => {
    const initialParams = config.initialRouteParams;
    const { param1, param2 } = initialParams;
    const initialNavigationData = store.getters.getInitialNavigationData;

    if (initialNavigationData.navigationToInitialRouteFinished) return true;

    /**
     * Handles query params coming from 3rd party that should be applied to lobby route path
     * parses incoming query params into normalized version
     */
    if (from === START_LOCATION && !to.redirectedFrom) {
      const finalParams = to.params;
      let initialRouteName;
      let initialRouteParams;

      if (param1 === 'game') {
        finalParams.gameId = getIdFromParam(param2);
      } else if (param1 || param2) {
        finalParams.groupName = getNameFromParam(param1);
        finalParams.groupId = getIdFromParam(param1);

        finalParams.subgroupName = getNameFromParam(param2);
        finalParams.subgroupId = getIdFromParam(param2);
      }

      initialRouteParams = pick(finalParams, ['gameId', 'groupId', 'subgroupId', 'type']);

      if (finalParams.gameId) {
        initialRouteName = constants.ROUTES.LOBBY_GAME;
      } else if (finalParams.subgroupName) {
        initialRouteName = constants.ROUTES.LOBBY_SUBGROUP;
      } else if (finalParams.groupName) {
        initialRouteName = constants.ROUTES.LOBBY_GROUP;
      } else if (!finalParams.groupName && !finalParams.subgroupName) {
        initialRouteName = constants.ROUTES.LOBBY_GROUP;

        initialRouteParams = {
          groupId: constants.GROUP_IDS.ALL,
          type: initialRouteParams.type,
        };
      } else {
        initialRouteName = constants.ROUTES.LOBBY;
      }

      await store.dispatch('setInitialNavigationData', {
        name: initialRouteName,
        params: initialRouteParams,
      });

      return true;
    }

    const routeParamsMatch = every(
      initialNavigationData.params,
      (value, key) => from.params[key]?.toString() === value?.toString(),
    );
    const routeMatchesInitialNavigationData =
      !initialNavigationData.navigationToInitialRouteFinished &&
      routeParamsMatch &&
      initialNavigationData.name === from.name;

    if (routeMatchesInitialNavigationData) {
      await store.dispatch('setInitialNavigationFinished');
    }

    return true;
  },

  /**
   * Notifies parent frame that route has changed
   * parses outgoing query params into dashed version
   */
  notifyRouteChange: (to) => {
    const { groupName, subgroupName, game, gameId, groupId, subgroupId } = to.params;

    const isGameQuery = game && gameId;
    const isGroupsQuery = !!groupId;
    const isInvalidQuery = !isGroupsQuery && !isGameQuery;

    if (isInvalidQuery) {
      return;
    }

    let params;
    let titleBreadcrumb;

    if (isGroupsQuery) {
      params = getGroupParams({
        groupId,
        groupName,
        subgroupId,
        subgroupName,
      });

      titleBreadcrumb = subgroupName ? [subgroupName, groupName] : [groupName];
    } else if (isGameQuery) {
      params = getGameParams(gameId, game);

      titleBreadcrumb = [game];
    }

    if (store.getters.getInitialNavigationData.navigationToInitialRouteFinished) {
      integrator.notifyRouteChange({
        path: to.fullPath,
        params,
      });
    }

    integrator.notifyChangeTitle(titleBreadcrumb);
  },
};

<template>
  <div
    v-if="isAppLoading"
    role="alert"
    class="center"
  >
    <AppLoader />
  </div>
  <AppNotificationModal
    v-else-if="hasBootstrapFailed"
    :confirm="translations.reloadAction"
    @confirm="reloadPage"
  />
  <router-view v-else />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AppNotificationModal from '@/components/common/AppNotificationModal';
import AppLoader from '@/components/common/loaders/AppLoader';
import integrator from '@/services/integrator';
import eventBus from '@/utility/eventBus';

export default {
  name: 'App',
  components: {
    AppLoader,
    AppNotificationModal,
  },
  data() {
    return {
      isAppLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      token: 'getUserToken',
      isUserLoggedIn: 'isUserLoggedIn',
      isGameModalVisible: 'isGameModalVisible',
      isNativeApp: 'isNativeApp',
      translations: 'translations',
      activeGame: 'getActiveGame',
      hasBootstrapFailed: 'hasBootstrapFailed',
      isIOS12: 'isIOS12',
      isVaixActive: 'isVaixActive',
      isAllGroupSelected: 'isAllGroupSelected',
      isMobileDevice: 'isMobileDevice',
      isLive: 'isLive',
    }),
  },
  async created() {
    await this.loadBootstrap();
    this.isAppLoading = false;
  },
  mounted() {
    // on iOS 12, safari mobile 12 the body width changes because of the swipe implementation, so we limit it
    // https://phabricator.nsoft.ba/T119252
    if (this.isIOS12) {
      this.limitBodyWidth();
    }

    integrator.notifyAppLoad();

    eventBus.subscribe('User.AuthorizationChanged', (message) => {
      const wasPreviouslyLoggedIn = this.isUserLoggedIn;

      // changes user logged in state
      this.changeAuthorization(message);

      const didUserLogout = !this.isUserLoggedIn && wasPreviouslyLoggedIn;
      const didUserLogin = this.isUserLoggedIn && !wasPreviouslyLoggedIn;

      if (didUserLogin) {
        this.handleUserLogin();
      }

      if (didUserLogout) {
        this.handleUserLogout();
      }
    });

    eventBus.subscribe('User.BalanceChanged', (message) => {
      this.changeBalance(message.balance);
    });

    eventBus.subscribe('VisualViewportScroll', (message) => {
      this.setVisualViewportScroll(message);
    });

    this.loadCarouselsIfVaixActive();

    if (this.isUserLoggedIn) {
      this.loadFavoriteGames(this.token);
    } else {
      this.loadFavoriteGamesForUnauthorized();
    }
  },
  methods: {
    ...mapActions([
      'loadFavoriteGames',
      'loadFavoriteGamesForUnauthorized',
      'changeAuthorization',
      'changeBalance',
      'openNotificationModal',
      'destroyActiveGameUrl',
      'loadBootstrap',
      'loadGames',
      'setVisualViewportScroll',
      'loadCarouselsData',
      'loadCarouselsDataForUnauthorized',
      'resetCarouselsData',
    ]),

    limitBodyWidth() {
      const viewportWidth = document.querySelector('html').clientWidth;
      document.body.style.maxWidth = `${viewportWidth}px`;
    },

    reloadPage() {
      window.location.reload();
    },

    async reloadGamesAndCarouselsIfVaixIsActive() {
      if (!this.isVaixActive) return;

      // clear cache on login/logout
      await this.loadCarouselsIfVaixActive({ shouldClearCache: true });
      this.loadGames({ page: 1, shouldClearCache: true });
    },

    async loadCarouselsIfVaixActive({ shouldClearCache } = {}) {
      if (!this.isVaixActive) return;

      await this.loadCarouselsData({
        shouldClearCache,
        playerLoggedIn: this.isUserLoggedIn,
      });
    },

    handleUserLogin() {
      this.loadFavoriteGames(this.token);

      if (this.isGameModalVisible) {
        this.onLoginShowNotification();
      }

      this.reloadGamesAndCarouselsIfVaixIsActive();
    },
    handleUserLogout() {
      this.loadFavoriteGamesForUnauthorized();

      if (this.isGameModalVisible) {
        this.onLogoutShowNotification();
        this.destroyActiveGameUrl();
      }

      this.reloadGamesAndCarouselsIfVaixIsActive();
      this.resetCarouselsData();
    },
    onLoginShowNotification() {
      this.openNotificationModal({
        message: this.translations.gameOpenLoginMessage,
      });
    },
    onLogoutShowNotification() {
      const { demoGameOpenLogoutMessage, gameOpenLogoutMessage } = this.translations;
      const message = this.activeGame.demo ? demoGameOpenLogoutMessage : gameOpenLogoutMessage;

      this.openNotificationModal({ message });
    },
  },
};
</script>

<style lang="scss">
.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
